<template>
  <div class="code-edit" v-if="!isProcessing">
    <form-qr class="code-edit__form" :collection=collection />
  </div>
</template>

<script>
import FormQr from '@/components/code/form/qr.vue'

export default {
  name: 'code_edit',
  components: { FormQr },
  mounted () {
    // QRコードが存在しない場合は404へ
    if (!this.collection) {
      this.$router.push({ name: 'notfound' })
      return
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} collectionのドキュメントID
     */
    cid () {
      return this.$route.params.cid
    },
    /**
     * @return {Object} collectionのオブジェクト
     */
    collection () {
      return this.$store.getters['collections/collection'](this.cid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.code-edit {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height} - #{$footer_height});
  margin: $header_height auto $footer_height auto;
  &__form {
    margin: 20px 0;
  }
}
</style>
